<template>
  <div class="page">
    <div class="page_pc">
      <!-- loading -->
      <div class="loading" id="loading">
        <img
          src="https://img.ohdat.io/producer_c/official_website/assets/home/loadingImg.png"
        />
        <div>
          LOADING
          <span>...</span>
        </div>
      </div>
      <div class="pagein">
        <!-- menu -->
        <div class="menu">
          <div class="left">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/logo.png"
              @click="home"
            />
            <span @click="home">HOME</span>
            <span @click="about">ABOUT</span>
            <span @click="stake">STAKE</span>
          </div>
          <div class="right">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/opensea.png"
              @click="opensea"
            />
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/twitter.png"
              @click="twitter"
            />
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/game.png"
              @click="game"
            />
            <!-- <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/share.png"
            /> -->
          </div>
        </div>
        <!-- banner -->
        <div class="banner" id="banner">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/banner.png"
          />
          <div class="btns" v-show="!btnListShow">
            <div v-if="loginWallet">
              <p class="content">Hi, {{ walletAddress }}</p>
              <div
                class="mint"
                @click="mintGo"
                v-if="status == 6 || status == 7"
              >
                claim
              </div>
              <div class="mint" @click="mintGo" v-else>Reserve</div>
            </div>
            <div class="wallet" v-else @click="connectWallet">
              CONNECT WALLET
            </div>
          </div>
          <div class="icon">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/arrow.png"
              @click="icondown"
            />
          </div>
        </div>
        <!-- 动画banner -->
        <div class="bannerDong">
          <!-- <img src="https://img.ohdat.io/producer_c/official_website/assets/home/bg1.png" /> -->
          <div class="dongin">
            <div class="c_d">
              <div class="icon">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/logo.png"
                />
              </div>
              <div class="text">
                Producer C, the first virtual brand created by iQIYI, is a robot
                panda that was built with cutting-edge technologies. Coming into
                web3 as a collection with thousands of NFTs, Producer C is going
                to build the galaxy of iQIYI and create a brand new horizon of
                pop culture and video entertainment.
              </div>
            </div>
            <div class="l_d" id="l_d">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/rightImg.png"
              />
            </div>
            <div class="r_d" id="r_d">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/leftImg.png"
              />
            </div>
          </div>
        </div>
        <!-- content -->
        <div class="section_content"></div>
        <div class="section_content">
          <div class="textSec textSec1">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/text1bg.png"
            />
            <div class="textin">
              <div class="tip">LORE</div>
              <div class="border">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/border1.png"
                />
              </div>
              <div class="con">
                One day on a spaceship heading to an unknown galaxy, Producer C,
                the captain, woke up from hibernation. Navigator reported that
                they are approaching a mata-galaxy with five planets, iQIYI,
                where video entertainments of the past 21st century still exist.
              </div>
            </div>
          </div>
        </div>
        <div class="section_content1"></div>
        <div class="section_content1">
          <div class="textSec textSec1">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/text1bg.png"
            />
            <div class="textin">
              <div class="tip">LORE</div>
              <div class="border">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/border1.png"
                />
              </div>
              <div class="con">
                Builders, scholars and adventurers. Producer C is now more than
                a captain! To rebuild the civilization of each planet, Producer
                C has embarked on a long, long space travel with his crew…
              </div>
            </div>
          </div>
        </div>
        <!-- Nft -->
        <div class="section_nft">
          <!-- <img src="https://img.ohdat.io/producer_c/official_website/assets/home/bg4.png" /> -->
          <div class="nftList">
            <div class="listin">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/1.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/2.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/3.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/4.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/5.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/6.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/7.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/8.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/9.png"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/10.png"
              />
            </div>
          </div>
        </div>
        <!-- 星球 -->
        <div class="gamelist">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/bgsection.png"
            class="secfix"
          />
          <div class="gameStart" id="gameStart">
            <img :src="bgBlue" />
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif"
              v-show="gifshow"
              class="gif"
            />
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/section.png"
              class="secImg"
            />
            <div
              class="bgClick"
              @click="ball"
              @mouseover="mouseover"
              @mouseleave="mouseleave"
            ></div>
            <div class="title">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/title.png"
              />
            </div>
            <div class="arrowup">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/tipBoxUp.png"
              />
            </div>
            <div class="arrow">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/wenzi.gif"
              />
            </div>
            <!-- <div class="arrows">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/arrow.gif"
            />
          </div> -->
            <div class="games" id="games">
              <div class="swiper">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start1.png"
                      alt=""
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start01.png"
                      alt=""
                      class="hover"
                      id="start01"
                    />
                  </div>
                  <div class="swiper-slide"></div>
                  <div class="swiper-slide">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start2.png"
                      alt=""
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start02.png"
                      alt=""
                      class="hover"
                      id="start02"
                    />
                  </div>
                  <div class="swiper-slide"></div>
                  <div class="swiper-slide">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start3.png"
                      alt=""
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start03.png"
                      alt=""
                      class="hover hover5"
                      id="start03"
                    />
                  </div>
                  <div class="swiper-slide"></div>
                  <div class="swiper-slide">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start4.png"
                      alt=""
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start04.png"
                      alt=""
                      class="hover"
                      id="start04"
                    />
                  </div>
                  <div class="swiper-slide"></div>
                  <div class="swiper-slide">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start5.png"
                      alt=""
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/start05.png"
                      alt=""
                      class="hover"
                      id="start05"
                    />
                  </div>
                  <div class="swiper-slide"></div>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="odiv">AEAEA Technology Ltd.</div>
              <div class="fixdiv">
                <span>© 2022 Producer C All Right Reserved</span>
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/opensea.png"
                  @click="opensea"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/twitter.png"
                  @click="twitter"
                  class="twitter"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/game.png"
                  @click="game"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 弹窗 -->
        <div class="tan" v-show="tanshow">
          <div class="tanin">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/tanbox.png"
            />
            <div class="close" @click="close">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/close.png"
              />
            </div>
            <div class="title">{{ title }}</div>
            <div class="main">
              <div class="left" v-show="startShow == 1">
                <span v-for="(v, index) in content1" :key="index">{{ v }}</span>
              </div>
              <div class="left" v-show="startShow == 2">
                <span v-for="(v, index) in content2" :key="index">{{ v }}</span>
              </div>
              <div class="left" v-show="startShow == 3">
                <span v-for="(v, index) in content3" :key="index">{{ v }}</span>
              </div>
              <div class="left" v-show="startShow == 4">
                <span v-for="(v, index) in content4" :key="index">{{ v }}</span>
              </div>
              <div class="left" v-show="startShow == 5">
                <span v-for="(v, index) in content5" :key="index">{{ v }}</span>
              </div>
              <div class="right">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/start1.png"
                  v-if="startShow == 1"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/start2.png"
                  v-else-if="startShow == 2"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/start3.png"
                  v-else-if="startShow == 3"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/start4.png"
                  v-else-if="startShow == 4"
                />
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/start5.png"
                  v-else-if="startShow == 5"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mintBox" v-show="mintTan">
          <div class="mintin">
            <div class="close" @click="closeMint">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
              />
            </div>
            <div class="title">{{ titles }}</div>
            <!-- 进度 -->
            <div
              class="process"
              v-show="status != -1 && status != 6 && status != 7"
            >
              <div
                class="line line1"
                :style="active2 ? 'border-top:2px solid #E18360' : ''"
              ></div>
              <div
                class="line line2"
                :style="active3 ? 'border-top:2px solid #E18360' : ''"
              ></div>
              <div class="processin">
                <div class="circle">
                  <!-- 白名单 4 -->
                  <div class="exp" v-if="expire1"></div>
                  <div v-else>
                    <div class="border" v-if="status == 4"></div>
                    <div class="cir_gray" v-else></div>
                  </div>
                </div>
                <div class="con" :style="active1 ? 'color:#E18360' : ''">
                  producer list
                </div>
              </div>
              <div class="processin">
                <div class="circle">
                  <!-- 等待名单 3 -->
                  <div class="exp" v-if="expire2"></div>
                  <div v-else>
                    <div class="border" v-if="status == 3"></div>
                    <div class="cir_gray" v-else></div>
                  </div>
                </div>
                <div class="con" :style="active2 ? 'color:#E18360' : ''">
                  wait list
                </div>
              </div>
              <div class="processin">
                <div class="circle">
                  <!-- 公售 1 -->
                  <div class="exp" v-if="expire3"></div>
                  <div v-else>
                    <div class="border" v-if="status == 1"></div>
                    <div class="cir_gray" v-else></div>
                  </div>
                </div>
                <div class="con" :style="active3 ? 'color:#E18360' : ''">
                  Public
                </div>
              </div>
            </div>
            <!-- 已售罄 -->
            <div class="soldout" v-if="status == -1">
              <div class="oimg">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
                />
              </div>
              <div class="gray">sold out</div>
              <p>Sold Out(You can claim on 7pm, 29 DEC 2022)</p>
            </div>
            <!-- status==6,7 -->
            <div class="claim" v-else-if="status == 6 || status == 7">
              <div class="oimg">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
                />
              </div>
              <div class="otext">{{ claimText }}</div>
              <div class="gray" v-if="!claim">claim</div>
              <div class="claimbtn" v-else @click="claimBtn">claim</div>
            </div>
            <!-- mint -->
            <div class="mintStatus" v-else>
              <div class="mintMain">
                <div class="nftImg">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
                  />
                </div>
                <div class="main">
                  <div class="text">
                    {{ tips }}
                  </div>
                  <div class="list">
                    <div class="olist">
                      <div class="left">Remaining:</div>
                      <div class="right">
                        {{ $store.state.order.orderInfo.stock }}/5000
                      </div>
                    </div>
                    <div class="olist">
                      <div class="left">Price:</div>
                      <div class="right">
                        <img
                          src="https://img.ohdat.io/producer_c/official_website/assets/home/ether.png"
                          class="ether"
                        />
                        <span>{{ ethPrice }}</span>
                      </div>
                    </div>
                    <div class="olist">
                      <div class="left">Quantity:</div>
                      <div class="right">
                        <div
                          v-if="limit > 1"
                          class="sel"
                          @click="seldownShow"
                          :style="!seltion ? '' : 'border-bottom:none'"
                        >
                          {{ selectNum }}
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/down.png"
                            v-if="!seltion"
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/up.png"
                            v-else
                          />
                        </div>
                        <div v-else class="selno">
                          <span>{{ limit }}</span>
                        </div>
                        <div class="selDown" v-show="seltion">
                          <div @click="selNum(itemSel)">{{ itemSel }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="olist">
                      <div class="left">Total:</div>
                      <div class="right">
                        <img
                          src="https://img.ohdat.io/producer_c/official_website/assets/home/ether.png"
                          class="ether"
                        />
                        <span>{{ totalPrice }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btnList">
                <div
                  :class="!btnShow ? 'noStart' : 'begain'"
                  @click="reserveBtn"
                >
                  {{ mintBtn }}
                  <!-- <div class="op"></div> -->
                </div>
                <div class="tipsOp">{{ mintOp }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="loadingTan" v-show="processing">
          <div class="loadingin">
            <div class="close" @click="closeProcess">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
              />
            </div>
            <div class="oimg">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/loadingImg.png"
              />
            </div>
            <div class="dong">
              processing
              <span>...</span>
            </div>
          </div>
        </div>
        <div class="success loadingTan" v-show="successful">
          <div class="loadingin">
            <div class="close" @click="closeSuccess">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
              />
            </div>
            <div class="op">Congratulations!</div>
            <div class="oimg">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
              />
            </div>
            <div
              class="otext textBtn"
              v-if="status == 6 || status == 7"
              @click="opensea"
            >
              <span>VIEW ON OPENSEA</span>
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/sea.png"
              />
            </div>
            <div class="otext" v-else>
              You can claim {{ successNum }} Producer C NFT(s) on 9am EST, 3 Jan
              2023.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_m">
      <div class="zheng">
        <!-- loading -->
        <div class="loading" id="loading_m">
          <img
            class="loadingimg"
            src="https://img.ohdat.io/producer_c/official_website/assets/home/loadingbg1.png"
          />
          <!-- <div> -->
          <img
            class="loadingImged"
            src="https://img.ohdat.io/producer_c/official_website/assets/home/loadingImg.png"
          />
          <!-- </div> -->

          <div>
            LOADING
            <span>...</span>
          </div>
        </div>
      </div>
      <div class="page_mBox">
        <full-page :options="options" ref="fullpage">
          <div class="section">
            <div class="box3">
              <div style="height: 100vh">
                <div class="first_screen">
                  <img
                    class="first_screenimg"
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/banner_M.png"
                    alt=""
                    srcset=""
                  />
                  <div class="btns" v-show="!btnListShow">
                    <div v-if="loginWallet">
                      <p class="content">Hi, {{ walletAddress }}</p>
                      <div
                        class="mint"
                        @click="mintGo"
                        v-if="status == 6 || status == 7"
                      >
                        claim
                      </div>
                      <div class="mint" @click="mintGo" v-else>Reserve</div>
                    </div>
                    <div class="wallet" v-else @click="connectWallet">
                      CONNECT WALLET
                    </div>
                  </div>
                  <div class="icon">
                    <img
                      @click="next"
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/arrow.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="box3">
              <div style="height: 100vh">
                <div class="second_screen">
                  <div class="wawa">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/wawa.png"
                      alt=""
                    />
                  </div>
                  <p class="collection">INTRODUCTION</p>
                  <p class="collection_text">
                    Producer C, the first virtual brand created by iQIYI, is a
                    robot panda that was built with cutting-edge technologies.
                    Coming into web3 as a collection with thousands of NFTs,
                    Producer C is going to build the galaxy of iQIYI and create
                    a brand new horizon of pop culture and video entertainment.
                  </p>
                  <div class="collection_ul">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/1.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/2.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/3.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/4.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/5.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/6.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/7.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/8.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/9.png"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/10.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="box3">
              <div style="height: 100vh">
                <div class="third_screen">
                  <img
                    class="third_screenimg"
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/third_screen.png"
                    alt=""
                  />
                  <p class="title">LORE</p>
                  <p class="text">
                    One day on a spaceship heading to an unknown galaxy,
                    Producer C, the captain, woke up from hibernation. Navigator
                    reported that they are approaching a mata-galaxy with five
                    planets, iQIYI, where video entertainments of the past 21st
                    century still exist.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="box3">
              <div style="height: 100vh">
                <div class="fourth_screen">
                  <img
                    class="fourth_screenimg"
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/fourth_screen.png"
                    alt=""
                  />
                  <div class="fourth_screenbo">
                    <p class="title">LORE</p>
                    <p class="text">
                      Builders, scholars and adventurers. Producer C is now more
                      than a captain! To rebuild the civilization of each
                      planet, Producer C has embarked on a long, long space
                      travel with his crew…
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="box3">
              <div style="height: 100vh">
                <div class="fifth_screen">
                  <img
                    class="fifth_screenblue"
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/blue.png"
                    alt=""
                  />
                  <img
                    class="fifth_screenimg"
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/section1.png"
                    alt=""
                  />
                  <div class="bgClick" @click="ballM"></div>
                  <div class="games" id="games">
                    <div class="swiper">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start1.png"
                            alt=""
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start01.png"
                            alt=""
                            class="hover"
                            id="startm01"
                          />
                        </div>
                        <!-- <div class="swiper-slide">
                          <img src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif" alt="" class="hover" />
                        </div> -->
                        <div class="swiper-slide">
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start2.png"
                            alt=""
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start02.png"
                            alt=""
                            class="hover"
                            id="startm02"
                          />
                        </div>
                        <!-- <div class="swiper-slide">
                          <img src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif" alt="" />
                        </div> -->
                        <div class="swiper-slide">
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start3.png"
                            alt=""
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start03.png"
                            alt=""
                            class="hover hover5"
                            id="startm03"
                          />
                        </div>
                        <!-- <div class="swiper-slide">
                          <img src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif" alt="" />
                        </div> -->
                        <div class="swiper-slide">
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start4.png"
                            alt=""
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start04.png"
                            alt=""
                            class="hover"
                            id="startm04"
                          />
                        </div>
                        <!-- <div class="swiper-slide">
                          <img src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif" alt="" />
                        </div> -->
                        <div class="swiper-slide">
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start5.png"
                            alt=""
                          />
                          <img
                            src="https://img.ohdat.io/producer_c/official_website/assets/home/start05.png"
                            alt=""
                            class="hover"
                            id="startm05"
                          />
                        </div>
                        <!-- <div class="swiper-slide">
                          <img src="https://img.ohdat.io/producer_c/official_website/assets/home/1.gif" alt="" />
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="footer_m">
                    <p class="footer_logo">
                      <img
                        src="https://img.ohdat.io/producer_c/official_website/assets/home/opensea.png"
                        @click="opensea"
                        class="opensea"
                      />
                      <img
                        src="https://img.ohdat.io/producer_c/official_website/assets/home/twitter.png"
                        @click="twitter"
                        class="twitter"
                      />
                      <img
                        src="https://img.ohdat.io/producer_c/official_website/assets/home/game.png"
                        @click="game"
                      />
                    </p>
                    <p class="footer_mtext">AEAEA Technology Ltd.</p>
                    <p class="footer_mtext">
                      © 2022 Producer C All Right Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </full-page>
      </div>
      <!-- 弹窗 -->
      <div class="tan" v-show="tanshow">
        <div class="tanin">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/tanbox_m.png"
          />
          <div class="close" @click="close">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/close.png"
            />
          </div>
          <div class="title">{{ title }}</div>
          <div class="main">
            <div class="left" v-show="startShow == 1">
              <span v-for="(v, index) in content1" :key="index">{{ v }}</span>
            </div>
            <div class="left" v-show="startShow == 2">
              <span v-for="(v, index) in content2" :key="index">{{ v }}</span>
            </div>
            <div class="left" v-show="startShow == 3">
              <span v-for="(v, index) in content3" :key="index">{{ v }}</span>
            </div>
            <div class="left" v-show="startShow == 4">
              <span v-for="(v, index) in content4" :key="index">{{ v }}</span>
            </div>
            <div class="left" v-show="startShow == 5">
              <span v-for="(v, index) in content5" :key="index">{{ v }}</span>
            </div>
            <div class="right">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/start11.png"
                v-if="startShow == 1"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/start2.png"
                v-else-if="startShow == 2"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/start3.png"
                v-else-if="startShow == 3"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/start4.png"
                v-else-if="startShow == 4"
              />
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/start5.png"
                v-else-if="startShow == 5"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mintBox" v-show="mintTan">
        <div class="mintin">
          <div class="close" @click="closeMint">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
            />
          </div>
          <div class="title">{{ titles }}</div>
          <!-- status==6,7 -->
          <div class="claim" v-if="status == 6 || status == 7">
            <div class="oimg">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
              />
            </div>
            <div class="otext">{{ claimText }}</div>
            <div class="gray" v-if="!claim">claim</div>
            <div class="claimbtn" v-else @click="claimBtn">claim</div>
          </div>
          <!-- mint -->
          <div class="mintMain" v-else>
            <div class="top">
              <div class="oimg">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
                />
              </div>
              <div class="process">
                <div
                  class="line line1"
                  :style="active2 ? 'border-left:2px solid #E18360' : ''"
                ></div>
                <div
                  class="line line2"
                  :style="active3 ? 'border-left:2px solid #E18360' : ''"
                ></div>
                <div class="processin processin1">
                  <div class="circle">
                    <!-- 白名单 4 -->
                    <div class="exp" v-if="expire1"></div>
                    <div v-else>
                      <div class="border" v-if="status == 4"></div>
                      <div class="cir_gray" v-else></div>
                    </div>
                  </div>
                  <div class="con" :style="active1 ? 'color:#E18360' : ''">
                    producer list
                  </div>
                </div>
                <div class="processin processin2">
                  <div class="circle">
                    <!-- 等待名单 3 -->
                    <div class="exp" v-if="expire2"></div>
                    <div v-else>
                      <div class="border" v-if="status == 3"></div>
                      <div class="cir_gray" v-else></div>
                    </div>
                  </div>
                  <div class="con" :style="active2 ? 'color:#E18360' : ''">
                    wait list
                  </div>
                </div>
                <div class="processin">
                  <div class="circle">
                    <!-- 公售 1 -->
                    <div class="exp" v-if="expire3"></div>
                    <div v-else>
                      <div class="border" v-if="status == 1"></div>
                      <div class="cir_gray" v-else></div>
                    </div>
                  </div>
                  <div class="con" :style="active3 ? 'color:#E18360' : ''">
                    Public
                  </div>
                </div>
              </div>
            </div>
            <div class="text">{{ tips }}</div>
            <div class="list">
              <div class="olist">
                <div class="left">Remaining:</div>
                <div class="right">
                  {{ $store.state.order.orderInfo.stock }}/5000
                </div>
              </div>
              <div class="olist">
                <div class="left">Price:</div>
                <div class="right">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/ether.png"
                    class="ether"
                  />
                  <span>{{ ethPrice }}</span>
                </div>
              </div>
              <div class="olist">
                <div class="left">Quantity:</div>
                <div class="right">
                  <div v-if="limit > 1" class="sel" @click="seldownShow">
                    <span>{{ selectNum }}</span>
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/down.png"
                      v-if="!seltion"
                    />
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/home/up.png"
                      v-else
                    />
                  </div>
                  <div v-else class="selno">
                    <span>{{ limit }}</span>
                  </div>
                  <div class="selDown" v-show="seltion">
                    <div @click="selNum(itemSel)">{{ itemSel }}</div>
                  </div>
                </div>
              </div>
              <div class="olist">
                <div class="left">Total:</div>
                <div class="right">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/home/ether.png"
                    class="ether"
                  />
                  <span>{{ totalPrice }}</span>
                </div>
              </div>
            </div>
            <div class="btnList">
              <div :class="!btnShow ? 'noStart' : 'begain'" @click="reserveBtn">
                {{ mintBtn }}
              </div>
              <div class="tipsOp">{{ mintOp }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="loadingTan" v-show="processing">
        <div class="loadingin">
          <div class="close" @click="closeProcess">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
            />
          </div>
          <div class="oimg">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/loadingImg.png"
            />
          </div>
          <div class="dong">
            processing
            <span>...</span>
          </div>
        </div>
      </div>
      <div class="success loadingTan" v-show="successful">
        <div class="loadingin">
          <div class="close" @click="closeSuccess">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png"
            />
          </div>
          <div class="op">Congratulations!</div>
          <div class="oimg">
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/nft.gif?tr=w-200,h-200"
            />
          </div>
          <div
            class="otext textBtn"
            v-if="status == 6 || status == 7"
            @click="opensea"
          >
            <span>VIEW ON OPENSEA</span>
            <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/sea.png"
            />
          </div>
          <div class="otext" v-else>
            You can claim {{ successNum }} Producer C NFT(s) on 9am EST, 3 Jan
            2023.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connectWallet, getCurrentAccount, checkChain } from "@/utils/login.js";
import { get_goods_datail, get_purchase_options } from "@/api/goods/index.js";
import {
  place_goods_order_submit,
  place_goods_order,
} from "@/api/trade/index.js";
import {
  reserve,
  waitListReserve,
  whiteListReserve,
  getReservedQuantity,
  getClaimedQuantity,
  claim,
} from "@/utils/web3.js";
import Swiper from "swiper";
import "swiper/css/swiper.css";
const web3 = require("web3");
let indexActive;
let indexActiveM;
var delaytime = 0;
var vm = null;
export default {
  name: "iqiyi",
  data() {
    return {
      contractAddress: "",
      reservedQuantity: 0,
      claimedQuantity: 0,
      acIndex: "",
      percent: "",
      preloadCount: 0,
      startShow: 0,
      arrText: [
        {
          title: "Music",
          content:
            "“Music + trendy culture” has always been the main track that iQIYI focused on. While bringing music culture to young people continually, IQIYI's music programs use innovative technology to break the barriers together with brand IP to create a digital marketing case that crosses media and deeply connects users. The fun games and performances in the program will break the limits of the traditional stage to deeply communicate with users, while upgrading communication efficiency, and strongly capturing users' attention and favor while web3 trends continue to rise.",
        },
        {
          title: "iCHCU",
          content:
            "iQIYI Chinese Historic City Universe(iCHCU) is a series IP which deeply cultivates the historical and cultural heritage, explores the cultural kernel of ancient city features, and aims to recreate the historical civilization of China. iQIYI develops multiple content forms and builds a unified worldview for series with distinctive regional culture, breaking the barriers of characters, time, space and events between different stories, and finally forming a universe-level IP with a common cultural kernel.",
        },
        {
          title: "Trendy Culture",
          content:
            "Based on the new trend of young people's pursuit of trendy culture, iQIYI responds by building a platform for young users to “communicate” with trendy brands through the program, so that the program no longer stops at the introduction, but also communication and identification of trendy brands, thus contributing to the gathering of groups with the same concept through the act of purchasing which brings a more convenient consumption experience and a new viewing experience for viewers.",
        },
        {
          title: "Light on Theater",
          content:
            "Launched in 2020, the Light On Theater is a major installment in iQIYI’s endeavors in the suspense genre, containing numbers of short episodes of high-quality mystery content and a new theater operation model to enhance the user's viewing experience. In addition to the design ingenuity of contents and subject matters, iQIYI also aims to accomplish offline interactive experience of brands and designer toys in order to increase the brand influences.",
        },

        {
          title: "Sweet On Theater",
          content:
            "Sweet On Theater, launched by iQIYI in 2021, is an emotional theater series with high concentration of emotional content to create a romantic heartwarming experience.The theater will explore the more socially significant love topics and build a “Love Drama Room” through integrating and optimizing the “Watch Together” function to bring an immersive drama watching and socializing experience for users.",
        },
      ],
      conentText: "",
      content1: [],
      content2: [],
      content3: [],
      content4: [],
      content5: [],
      title: "",
      tanshow: false,
      timer: null,
      chars: "",
      flag: true,
      flagm: true,
      menu_status: false,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        //是否显示导航，默认为false
        navigation: false,
        autoScrolling: true,
      },
      indexActiveed: "",
      bgBlue:
        "https://img.ohdat.io/producer_c/official_website/assets/home/blue.png",
      gifshow: false,
      stock: 0,
      status: 0,
      mintTan: false,
      loginWallet: false,
      walletAddress: "",
      currentAccounttext: "",
      seltion: false,
      nums: [1, 2],
      selectNum: 2,
      itemSel: 1,
      totalPrice: "",
      active1: false,
      active2: false,
      active3: false,
      expire1: false,
      expire2: false,
      expire3: false,
      tips: "",
      ethPrice: "",
      btnShow: true,
      mintBtn: "",
      mintOp: "",
      claim: false,
      titles: "Producer C Reservation",
      contract: "",
      processing: false,
      successful: false,
      limit: "",
      claimText: "",
      btnListShow: false,
      successNum: "",
    };
  },
  mounted() {
    this.swipper();
    this.preload();
    this.checkLogIn();
    this.watchWeb3Event();
    this.getGoodsDatail();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {},
  watch: {},
  created() {
    vm = this;
  },
  methods: {
    //reserve
    reserveBtn() {
      console.log(this.btnShow, this.contract);
      if (this.limit < 1) {
        this.selectNum = this.limit;
      }
      console.log(this.selectNum);
      if (this.btnShow) {
        if (this.contract == "public_reserve") {
          this.reserve();
        } else if (this.contract == "wait_list_reserve") {
          this.waitListReserve();
        } else if (this.contract == "white_list_reserve") {
          this.whiteListReserve();
        }
      }
    },
    async reserve() {
      await checkChain();
      let that = this;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.selectNum);
      formData.append("goods_id", "11");
      formData.append("quantity", this.selectNum);
      that.successNum = that.selectNum;
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        reserve(orderInfo)
          .on("transactionHash", function (hash) {
            console.log(111);
            that.placeGoodsOrderSubmit(orderInfo.nonce);
            that.mintTan = false;
            that.processing = true;
            that.btnListShow = true;
            that.getGoodsDatail();
            that.getPurchaseOptions();
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log(receipt, "receipt");
            that.processing = false;
            that.successful = true;
            that.btnListShow = true;
          })
          .on("error", function (error, receipt) {
            console.log(error);
          });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    async waitListReserve() {
      await checkChain();
      let that = this;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.selectNum);
      formData.append("goods_id", "11");
      formData.append("quantity", this.selectNum);
      that.successNum = that.selectNum;
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        waitListReserve(orderInfo)
          .on("transactionHash", function (hash) {
            console.log(111);
            that.placeGoodsOrderSubmit(orderInfo.nonce);
            that.mintTan = false;
            that.processing = true;
            that.btnListShow = true;
            that.getGoodsDatail();
            that.getPurchaseOptions();
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log(receipt, "receipt");
            that.processing = false;
            that.successful = true;
            that.btnListShow = true;
          })
          .on("error", function (error, receipt) {
            console.log(error);
          });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    async whiteListReserve() {
      await checkChain();
      let that = this;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.selectNum);
      formData.append("goods_id", "11");
      formData.append("quantity", this.selectNum);
      that.successNum = that.selectNum;
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        whiteListReserve(orderInfo)
          .on("transactionHash", function (hash) {
            console.log(111);
            that.placeGoodsOrderSubmit(orderInfo.nonce);
            that.mintTan = false;
            that.processing = true;
            that.btnListShow = true;
            that.getGoodsDatail();
            that.getPurchaseOptions();
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log(receipt, "receipt");
            that.processing = false;
            that.successful = true;
            that.btnListShow = true;
          })
          .on("error", function (error, receipt) {
            console.log(error);
          });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    async placeGoodsOrderSubmit(nonce) {
      let formData = new FormData();
      formData.append("nonce", nonce);
      let res = await place_goods_order_submit(formData);
      console.log("placeGoodsOrderSubmit", res);
      if (res.code == 200) {
        console.log("place_goods_order_sbmit", "true");
        // this.$store.commit('mask/changeIndex', 1)
      } else {
        console.log("place_goods_order_sbmit", "err");
      }
    },

    async claimBtn() {
      await checkChain();
      let that = this;
      claim(
        that.currentAccounttext,
        that.reservedQuantity - that.claimedQuantity,
        that.contractAddress
      )
        .on("transactionHash", function (hash) {
          that.mintTan = false;
          that.processing = true;
          that.getGoodsDatail();
          that.getPurchaseOptions();
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          console.log("confirmationNumber", confirmationNumber);
        })
        .on("receipt", function (receipt) {
          console.log(receipt, "receipt");
          that.processing = false;
          that.successful = true;
        })
        .on("error", function (error, receipt) {
          console.log(error);
        });
    },
    //下拉选择数量
    seldownShow() {
      this.seltion = !this.seltion;
      if (this.selectNum == 2) {
        this.itemSel = 1;
      } else {
        this.itemSel = 2;
      }
    },
    selNum(i) {
      this.selectNum = i;
      this.totalPrice = (this.ethPrice * this.selectNum).toFixed(2);
      this.seltion = !this.seltion;
    },
    //mint 按钮
    mintGo() {
      this.mintTan = true;
      this.btnListShow = true;
      this.getPurchaseOptions();
    },
    //status获取
    async getGoodsDatail() {
      const params = {
        id: 11,
      };
      let res = await get_goods_datail(params);
      console.log(res);
      if (res.code == 200) {
        this.stock = res.data.stock;
        this.status = res.data.status;
        this.contractAddress = res.data.token_address;
        this.$store.commit("order/POST_GOODS_INFO", res.data);
        // this.status = 6;
        if (res.data.status == 1) {
          //public
          this.expire3 = false;
          this.expire2 = true;
          this.expire1 = true;
          this.active3 = true;
          this.active2 = true;
          this.active1 = true;
        } else if (res.data.status == 3) {
          //wait
          this.expire3 = false;
          this.expire2 = false;
          this.expire1 = true;
          this.active3 = false;
          this.active2 = true;
          this.active1 = true;
        } else if (res.data.status == 4) {
          //白名单
          this.expire3 = false;
          this.expire2 = false;
          this.expire1 = false;
          this.active3 = false;
          this.active2 = false;
          this.active1 = true;
        }
        // if (res.data.status == 0) {
        //   this.btnShow = false;
        //   //未开始
        //   this.tips =
        //     "You are not eligible to reserve now. Please wait till Wait List reservation.";
        //   this.mintBtn = "Reserve";
        //   this.mintOp = "Start on 7pm, 29 DEC 2022";
        // }
      } else {
        console.log("res err", res);
      }
    },
    //mint 状态
    async getPurchaseOptions() {
      if (this.status != 6 && this.status != 7) {
        const params = {
          id: 11,
        };
        let res = await get_purchase_options(params);
        console.log(res);
        if (res.code === 200) {
          this.ethPrice = web3.utils.fromWei(res.data.price + "", "ether");
          this.contract = res.data.contract_method;
          this.limit = res.data.purchase_limit;
          if (this.limit == 2) {
            this.selectNum = 2;
          } else {
            this.selectNum = this.limit;
          }
          this.totalPrice = (this.ethPrice * this.selectNum).toFixed(2);
          if (this.totalPrice == "0.00") {
            this.totalPrice = 0;
          }
          this.titles = "Producer C Reservation";
          if (this.status == 0) {
            this.btnShow = false;
            this.mintBtn = "Reserve";
            this.mintOp = "Please wait till 9am EST, 29 DEC 2022";
            this.tips = "Reservation not started. Please wait.";
          } else if (res.data.available == 0) {
            //按钮置灰
            this.btnShow = false;
            this.mintBtn = "Reserve";
            this.mintOp = "";

            if (res.data.contract_method == "public_reserve") {
              this.tips =
                "You are not eligible to reserve now. Please wait till Public reservation.";
            } else if (res.data.contract_method == "wait_list_reserve") {
              this.tips =
                "You are not eligible to reserve now. Please wait till Wait List reservation.";
            } else if (res.data.contract_method == "white_list_reserve") {
              this.tips =
                "You are not eligible to reserve now. Please wait till White List reservation.";
            }
          } else if (res.data.purchase_limit == 0) {
            this.btnShow = false;
            this.mintBtn = "Reserve";
            this.mintOp =
              "If you reserve successfully, you can claim your NFT(s) afterwards on 9am EST, 3 Jan 2023";
            this.tips =
              "Congratulations! You have already reserved 2 Producer C NFT(s).";
          } else if (res.data.stock == 0) {
            this.btnShow = false;
            this.mintBtn = "Reserve";
            this.mintOp = "Sold out!";
            this.tips = "Sold out!";
          } else {
            this.btnShow = true;
            this.mintBtn = "Reserve";
            this.mintOp =
              "If you reserve successfully, you can claim your NFT(s) afterwards on 9am EST, 3 Jan 2023";
            if (res.data.contract_method == "public_reserve") {
              this.tips = "You can reserve your NFTs now!";
            } else if (res.data.contract_method == "wait_list_reserve") {
              this.tips = "You can reserve your NFTs now!";
            } else if (res.data.contract_method == "white_list_reserve") {
              this.tips =
                "You are producer-listed and can reserve your NFTs now!";
            }
          }
        } else if (
          res.code === 1101 ||
          res.code === 1102 ||
          res.code === 1103 ||
          res.code === 1104 ||
          res.code === 1105
        ) {
          // this.available = 0;
          // this.totalPrice = 0;
          // this.limit = 0;
        }
      } else {
        // alert()
        await getReservedQuantity(this.currentAccounttext, this.contractAddress)
          .then((result) => {
            this.reservedQuantity = result;
          })
          .catch((err) => {
            console.log(err, "err 查询失败");
          })
          .finally((repeat) => {
            // console.log(repeat, "repeat 查询成功2");
          });

        await getClaimedQuantity(this.currentAccounttext, this.contractAddress)
          .then((result) => {
            this.claimedQuantity = result;
            // alert(result)
          })
          .catch((err) => {
            console.log(err, "err 查询失败");
          })
          .finally((repeat) => {
            // console.log(repeat, "repeat 查询成功2");
          });

        if (this.reservedQuantity == 0) {
          // 不可领取
          this.claim = false;
          // sold out
          this.titles = "We are out of stock";
          this.claimText = "You have not reserved any Producer C NFT!";
        } else if (this.claimedQuantity == this.reservedQuantity) {
          //已领取过了
          this.claim = false;
          this.titles = "Claim Your Producer C";
          this.claimText = `You have already claimed ${this.claimedQuantity} Producer C NFT(s).`;
        } else if (
          this.status == 6 &&
          this.reservedQuantity - this.claimedQuantity > 0
        ) {
          //未到领取时间
          this.claim = false;
          this.titles = "Claim Your Producer C";
          this.claimText = `You can claim ${
            this.reservedQuantity - this.claimedQuantity
          } Producer C NFT(s) at 9am EST, 3 Jan 2023.`;
        } else if (
          this.status == 7 &&
          this.reservedQuantity - this.claimedQuantity > 0
        ) {
          //可领取
          this.claim = true;
          this.titles = "Claim Your Producer C";
          this.claimText = `Congratulations! You can claim ${
            this.reservedQuantity - this.claimedQuantity
          } Producer C NFT(s).`;
        }
      }
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount) {
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.currentAccounttext = currentAccount;
        this.loginWallet = true;
        this.walletAddress =
          currentAccount.substr(0, 4) + "***" + currentAccount.substr(-4);
        // this.check_have_peking_monsters();
      } else {
        this.loginWallet = false;
      }
    },
    async connectWallet() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.loginWallet = true;
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        if (res[0]) {
          if (localStorage.getItem("wallet_address") != res[0]) {
            localStorage.setItem("wallet_address", res[0]);
          }
          that.currentAccounttext = res[0];
          that.walletAddress = res[0].substr(0, 4) + "***" + res[0].substr(-4);
          that.loginWallet = true;
          that.getGoodsDatail();
          that.getPurchaseOptions();
        } else {
          that.loginWallet = false;
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
            } else {
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },
    closeMint() {
      this.mintTan = false;
      this.btnListShow = false;
    },
    closeSuccess() {
      this.successful = false;
      this.btnListShow = false;
    },
    closeProcess() {
      this.processing = false;
      this.btnListShow = false;
    },
    next() {
      this.$refs.fullpage.api.moveSectionDown();
    },
    preload() {
      var loading;
      if (window.screen.width < 1024) {
        loading = document.getElementById("loading_m");
      } else {
        loading = document.getElementById("loading");
      }
      let imgs = [
        "https://img.ohdat.io/producer_c/official_website/assets/home/loadingImg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/logo.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/opensea.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/twitter.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/game.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/share.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/banner.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/arrow.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/bg1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/rightImg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/leftImg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/text1bg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/border1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/bg4.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/2.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/3.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/4.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/5.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/6.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/7.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/8.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/9.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/10.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/bgsection.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/1.gif",
        "https://img.ohdat.io/producer_c/official_website/assets/home/section.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/title.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/tipBoxUp.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/wenzi.gif",
        "https://img.ohdat.io/producer_c/official_website/assets/home/arrow.gif",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start01.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start2.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start02.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start3.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start03.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start4.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start04.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start5.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start05.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/tanbox.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/close.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/loadingbg1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/banner_M.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/wawa.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/third_screen.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/fourth_screen.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/blue.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/section1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/tanbox_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/start11.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/blue1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/threebar.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/big.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/loadingbg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/meau.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/mint.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/bannerfix1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/bannerfix.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/claim_gray_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/claim_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/claim.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/graybtn.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/claim_gray.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/reserve_gray.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/reserve.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/closemint.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/ether.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/down.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/up.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/sea.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/loadingbg1.png",
      ];
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.preloadCount++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.preloadCount / imgs.length) * 100);
          this.percent = `${percentNum}%`;
          // console.log(this.percent);
          if (percentNum == 100) {
            // console.log(888);
            loading.classList.add("loadingtransform");
            // console.log(loading.classList);
          }
        };
      }
    },
    swipper() {
      let that = this;
      new Swiper(".swiper", {
        // autoplay: false,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000, //2000
        },
        speed: 2000,
        loop: true, // 循环模式选项
        on: {
          slideChangeTransitionStart: function () {
            indexActive = this.activeIndex;
            var gameStart = document.getElementById("gameStart");
            indexActiveM = (this.activeIndex - 1 + 5) % 5;
            if (
              this.activeIndex == 2 ||
              this.activeIndex == 4 ||
              this.activeIndex == 6 ||
              this.activeIndex == 8 ||
              this.activeIndex == 10
            ) {
              that.gifshow = true;
              that.bgBlue =
                "https://img.ohdat.io/producer_c/official_website/assets/home/blue1.png";
              gameStart.classList.add("shock");
              setTimeout(() => {
                that.gifshow = false;
                that.bgBlue =
                  "https://img.ohdat.io/producer_c/official_website/assets/home/blue.png";
                gameStart.classList.remove("shock");
              }, 6000);
            }
          },
          slideChangeTransitionEnd: function () {
            vm.indexActiveed = delaytime;
            if (
              this.activeIndex == 1 ||
              this.activeIndex == 3 ||
              this.activeIndex == 5 ||
              this.activeIndex == 7 ||
              this.activeIndex == 9
            ) {
              delaytime = 10000;
            }
          },
        },
      });
    },
    icondown() {
      let bannerHei = document.getElementById("banner");
      let r_d = document.getElementById("r_d");
      let l_d = document.getElementById("l_d");

      document.body.scrollTop = bannerHei.clientHeight / 2;
      // console.log(bannerHei.clientHeight, bannerHei.offsetHeight);
    },
    handleScroll(e) {
      let bannerHei = document.getElementById("banner");
      let r_d = document.getElementById("r_d");
      let l_d = document.getElementById("l_d");
      // let scrollY = e.target.scrollTop;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        e.target.scrollTop;
      if (scrollTop >= bannerHei.offsetHeight / 5) {
        r_d.classList.add("dong_r");
        l_d.classList.add("dong_l");
        setTimeout(() => {
          r_d.classList.remove("dong_rr");
          l_d.classList.remove("dong_ll");
        }, 100);
      }
      if (scrollTop <= bannerHei.offsetHeight / 6) {
        l_d.classList.add("dong_ll");
        r_d.classList.add("dong_rr");
        setTimeout(() => {
          r_d.classList.remove("dong_r");
          l_d.classList.remove("dong_l");
        }, 100);
      }
    },
    mouseover() {
      var start01 = document.getElementById("start01");
      var start02 = document.getElementById("start02");
      var start03 = document.getElementById("start03");
      var start04 = document.getElementById("start04");
      var start05 = document.getElementById("start05");
      console.log(indexActive);
      if (indexActive == 11) {
        start01.style.display = "block";
      } else if (indexActive == 3) {
        start02.style.display = "block";
      } else if (indexActive == 5) {
        start03.style.display = "block";
      } else if (indexActive == 7) {
        start04.style.display = "block";
      } else if (indexActive == 9) {
        start05.style.display = "block";
      }
    },
    mouseleave() {
      console.log(indexActive);
      var start01 = document.getElementById("start01");
      var start02 = document.getElementById("start02");
      var start03 = document.getElementById("start03");
      var start04 = document.getElementById("start04");
      var start05 = document.getElementById("start05");
      start01.style.display = "none";
      start02.style.display = "none";
      start03.style.display = "none";
      start04.style.display = "none";
      start05.style.display = "none";
    },
    ball() {
      this.content1 = [];
      this.conentText = "";
      this.chars = "";
      if (this.flag) {
        this.flag = false;
        if (indexActive == 11) {
          this.tanshow = true;
          this.startShow = 1;
          this.conentText = this.arrText[0].content;
          this.title = this.arrText[0].title;
        } else if (indexActive == 3) {
          this.tanshow = true;
          this.startShow = 2;
          this.conentText = this.arrText[1].content;
          this.title = this.arrText[1].title;
        } else if (indexActive == 5) {
          this.tanshow = true;
          this.startShow = 3;
          this.conentText = this.arrText[2].content;
          this.title = this.arrText[2].title;
        } else if (indexActive == 7) {
          this.tanshow = true;
          this.startShow = 4;
          this.conentText = this.arrText[3].content;
          this.title = this.arrText[3].title;
        } else if (indexActive == 9) {
          this.tanshow = true;
          this.startShow = 5;
          this.conentText = this.arrText[4].content;
          this.title = this.arrText[4].title;
        }
        this.setTime(indexActive);

        setTimeout(() => {
          this.flag = true;
        }, 3000);
      }
    },
    ballM() {
      this.content1 = [];
      this.conentText = "";
      this.chars = "";
      console.log(indexActiveM);
      if (this.flagm) {
        this.flagm = false;
        if (indexActiveM == 0) {
          this.tanshow = true;
          this.startShow = 1;
          this.conentText = this.arrText[0].content;
          this.title = this.arrText[0].title;
        } else if (indexActiveM == 1) {
          this.tanshow = true;
          this.startShow = 2;
          this.conentText = this.arrText[1].content;
          this.title = this.arrText[1].title;
        } else if (indexActiveM == 2) {
          this.tanshow = true;
          this.startShow = 3;
          this.conentText = this.arrText[2].content;
          this.title = this.arrText[2].title;
        } else if (indexActiveM == 3) {
          this.tanshow = true;
          this.startShow = 4;
          this.conentText = this.arrText[3].content;
          this.title = this.arrText[3].title;
        } else if (indexActiveM == 4) {
          this.tanshow = true;
          this.startShow = 5;
          this.conentText = this.arrText[4].content;
          this.title = this.arrText[4].title;
        }
        this.setTimeM(indexActiveM);

        setTimeout(() => {
          this.flagm = true;
        }, 3000);
      }
    },
    setTime(num) {
      this.content1 = [];
      this.content2 = [];
      this.content3 = [];
      this.content4 = [];
      this.content5 = [];
      this.chars = "";
      const text = this.conentText;
      this.chars = text.split("");
      var that = this;
      var times = 30;
      if (num == 11) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content1.push(v);
          }, k * times);
        });
      } else if (num == 3) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content2.push(v);
          }, k * times);
        });
      } else if (num == 5) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content3.push(v);
          }, k * times);
        });
      } else if (num == 7) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content4.push(v);
          }, k * times);
        });
      } else if (num == 9) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content5.push(v);
          }, k * times);
        });
      }
    },
    setTimeM(num) {
      this.content1 = [];
      this.content2 = [];
      this.content3 = [];
      this.content4 = [];
      this.content5 = [];
      this.chars = "";
      const text = this.conentText;
      this.chars = text.split("");
      var that = this;
      var times = 30;
      if (num == 0) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content1.push(v);
          }, k * times);
        });
      } else if (num == 1) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content2.push(v);
          }, k * times);
        });
      } else if (num == 2) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content3.push(v);
          }, k * times);
        });
      } else if (num == 3) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content4.push(v);
          }, k * times);
        });
      } else if (num == 4) {
        that.chars.forEach(function (v, k) {
          setTimeout(function () {
            that.content5.push(v);
          }, k * times);
        });
      }
    },
    close() {
      this.tanshow = false;
      this.content1 = [];
      this.content2 = [];
      this.content3 = [];
      this.content4 = [];
      this.content5 = [];
    },
    opensea() {
      window.open("https://opensea.io/collection/producerc");
    },
    twitter() {
      window.open("https://twitter.com/producerc_nft");
    },
    game() {
      window.open("https://discord.gg/producerc");
    },
    Menu() {
      this.menu_status = !this.menu_status;
    },
    home() {
      this.$router.push("/");
    },
    about() {
      this.$router.push("/about");
    },
    stake(){
      this.$router.push("/stake");
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>